import { useSnackbar as useReactSimpleSnackbar } from 'react-simple-snackbar';
import { useTheme } from 'styled-components';

const useSnackbar = ({ error = false, style } = {}) => {
  const theme = useTheme();

  return useReactSimpleSnackbar({
    position: 'bottom',
    style: {
      backgroundColor: error ? theme.colors.error : theme.colors.primary,
      borderRadius: theme.radii[1],
      boxShadow: 'none',
      fontFamily: theme.fonts.primary,
      fontSize: theme.fontSizes[3],
      ...style,
    },
  });
};

export default useSnackbar;
