import { useLocation } from '@reach/router';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Input from '@shoreag/base/Input';
import {
  composeValidations,
  match,
  minLength,
  required,
} from '@shoreag/validations';
import { Auth } from 'aws-amplify';
import { FORM_ERROR } from 'final-form';
import { parse } from 'query-string';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Field, Form as FinalForm } from 'react-final-form';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { ThemeContext } from 'styled-components';
import Redirect from '@shoreag/base/Redirect';
import FormError from '../../components/FormError';
import Logo from '../../components/Logo';
import SubmitButton from '../../components/SubmitButton';
import config from '../../config.json';
import AuthContainer from '../../containers/AuthContainer';
import updateUserMutation from '../../graphql/mutations/update-user.gql';
import LoginIllustration from '../../images/illustrations/login.svg';
import useSnackbar from '../../utilities/use-snackbar';

const PasswordResetPage = () => {
  const location = useLocation();
  const [updateUser] = useMutation(updateUserMutation);
  const [setSuccessSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });
  const { username, code } = parse(location.search);
  const [wasResetCodeSent, setWasResetCodeSent] = useState(false);
  const [hasResetCode, setHasResetCode] = useState(!!code);
  const [isSendingPasscode, setIsSendingPasscode] = useState(false);
  const theme = useContext(ThemeContext);

  const sendResetCode = async (username) => {
    await Auth.forgotPassword(username, {
      ResetUserPasswordEvent: 'forgotPassword',
    })
      .then(() => {
        setSuccessSnack(
          `An email was sent to ${username} with a verification code.`
        );
        setWasResetCodeSent(true);
      })
      .catch((error) => {
        setWasResetCodeSent(false);
        setErrorSnack(`There was a problem - ${error.message}`);
      });
    setIsSendingPasscode(false);
  };

  const displayCountDown = (remainingTime) => {
    const d = Number(remainingTime);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hh = h > 0 ? `0${h}`.slice(-2) : '';
    const mm = m > 0 ? `0${m}`.slice(-2) : '';
    const ss = s > 0 ? `0${s}`.slice(-2) : '00';
    return remainingTime > 0 ? (
      <Box
        sx={{
          fontSize: '.7rem',
          textAlign: 'center',
        }}
      >
        <Box>{`${hh}${h > 0 ? `:${mm}` : mm}${m > 0 ? `:${ss}` : ss}`}</Box>
      </Box>
    ) : (
      ''
    );
  };

  return (
    <AuthContainer>
      {({ signIn, mfaSetup, softwareTokenMfa }) => {
        return (
          <Box
            display={{ lg: 'flex' }}
            height={{ lg: '100%' }}
            minHeight="750px"
            sx={{ position: [null, null, null, null, 'absolute'] }}
            width={{ lg: '100%' }}
          >
            <Redirect to="/login" when={mfaSetup || softwareTokenMfa} />
            <Box
              alignItems="center"
              bg={{ lg: 'white' }}
              display="flex"
              height={{ lg: '100%' }}
              justifyContent={{ _: 'center', lg: 'flex-end' }}
              width={{ lg: '50%' }}
            >
              <Logo
                sx={{
                  left: '3rem',
                  mt: '3rem',
                  position: [null, null, null, null, 'absolute'],
                  top: '0',
                  width: '10rem',
                }}
              />
              <Box
                sx={{
                  display: ['none', null, null, null, 'block'],
                  maxWidth: '650px',
                  position: 'relative',
                  right: '-3rem',
                  top: '2rem',
                  width: '100%',
                }}
              >
                <LoginIllustration width="100%" />
              </Box>
            </Box>
            <Box
              alignItems="center"
              bg={{ lg: 'primary' }}
              display="flex"
              height={{ lg: '100%' }}
              justifyContent="center"
              px={5}
              py={8}
              width={{ lg: '50%' }}
            >
              <FinalForm
                initialValues={{ code, username }}
                onSubmit={async (values) => {
                  try {
                    // attempt to validate that this password hasn't been used.
                    // if something goes wrong, ¯\_(ツ)_/¯

                    try {
                      const {
                        data: { checkPassword },
                      } = await (
                        await fetch(config.apollo.url, {
                          body: JSON.stringify({
                            query: `{ checkPassword(password: "${values.newPassword}", email: "${values.username}") }`,
                          }),
                          headers: {
                            'content-type': 'application/json',

                            // using this api key is potentially risky if the UI
                            // isn't behind a firewall... maybe there's a cleaner
                            // way to handle this?
                            'x-api-key': config.apollo.apiKey,
                          },
                          method: 'POST',
                        })
                      ).json();

                      if (checkPassword) {
                        return {
                          [FORM_ERROR]:
                            'Please use a password that you haven’t used in the past',
                        };
                      }
                    } catch (e) {
                      setErrorSnack(
                        `There was a problem. Please try again. ${e}`
                      );
                    }

                    await Auth.forgotPasswordSubmit(
                      values.username,
                      values.code,
                      values.newPassword
                    )
                      .then(async () => {
                        await signIn({
                          password: values.newPassword,
                          username: values.username,
                        })
                          .then(async () => {
                            await updateUser({
                              variables: { password: values.newPassword },
                            })
                              .then(async () => {
                                setSuccessSnack(
                                  'Password successfully reset. Signed In with your new password.'
                                );
                              })
                              .catch((error) =>
                                setErrorSnack(
                                  `There was a problem. Please try again. ${error}`
                                )
                              );
                          })
                          .catch((e) => {
                            setErrorSnack(
                              `There was a problem. Please try again. ${e.message}`
                            );
                          });
                      })
                      .catch((error) =>
                        setErrorSnack(
                          `There was a problem resetting the password. Please try again. ${error.message}`
                        )
                      );
                  } catch (e) {
                    return { [FORM_ERROR]: e.message };
                  }
                }}
                render={(formContext) => (
                  <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                    <Box
                      as="h1"
                      color={{ lg: 'white' }}
                      fontSize={7}
                      mb={6}
                      textAlign={{ _: 'center', lg: 'left' }}
                    >
                      Password Reset
                    </Box>
                    <form onSubmit={formContext.handleSubmit}>
                      <Field
                        component={Input}
                        disabled={hasResetCode || wasResetCodeSent}
                        label="Username or Email"
                        name="username"
                        validate={composeValidations(required)}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          mt: 6,
                        }}
                      >
                        <Button
                          disabled={
                            (!username && !formContext.values.username) ||
                            !!formContext.errors.username ||
                            hasResetCode ||
                            wasResetCodeSent
                          }
                          onClick={() => {
                            setIsSendingPasscode(true);
                            sendResetCode(formContext.values.username);
                          }}
                          submitting={isSendingPasscode}
                          sx={{ mr: wasResetCodeSent ? 3 : '' }}
                          width="100%"
                        >
                          Send Reset Code
                        </Button>
                        {wasResetCodeSent && (
                          <CountdownCircleTimer
                            colors={[
                              [theme.colors.success, 0.33],
                              [theme.colors.warning, 0.33],
                              [theme.colors.error, 0.33],
                            ]}
                            duration={300}
                            isPlaying
                            onComplete={() => setWasResetCodeSent(false)}
                            size={43}
                            strokeWidth={2}
                          >
                            {({ remainingTime }) =>
                              displayCountDown(remainingTime)
                            }
                          </CountdownCircleTimer>
                        )}
                      </Box>
                      {!wasResetCodeSent && (
                        <Button
                          disabled={wasResetCodeSent}
                          onClick={() => {
                            setHasResetCode(!hasResetCode);
                          }}
                          simple
                          sx={{
                            color: hasResetCode ? 'error' : 'accent',
                            float: 'right',
                            fontSize: 2,
                            mt: 2,
                            opacity: wasResetCodeSent ? '.3' : '',
                          }}
                        >
                          {hasResetCode ? 'Cancel' : 'Already have a code.'}
                        </Button>
                      )}
                      <Field
                        component={Input}
                        disabled={!(hasResetCode || wasResetCodeSent)}
                        label="Verification Code"
                        name="code"
                        validate={composeValidations(required)}
                      />
                      <Field
                        component={Input}
                        disabled={!(hasResetCode || wasResetCodeSent)}
                        label="New Password"
                        name="newPassword"
                        type="password"
                        validate={composeValidations(
                          minLength(config.minPasswordLength),
                          required
                        )}
                      />
                      <Field
                        component={Input}
                        disabled={!(hasResetCode || wasResetCodeSent)}
                        label="Confirm Password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        validate={composeValidations(
                          required,
                          match('Passwords', formContext.values?.newPassword)
                        )}
                      />
                      <FormError>{formContext.submitError}</FormError>
                      <SubmitButton
                        data-cy="submit"
                        disabled={!(hasResetCode || wasResetCodeSent)}
                        submitting={formContext.submitting}
                        width={{ lg: '100%' }}
                      >
                        Reset Password
                      </SubmitButton>
                    </form>
                  </Box>
                )}
              />
            </Box>
          </Box>
        );
      }}
    </AuthContainer>
  );
};

export default PasswordResetPage;
